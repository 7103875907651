import {addQueryParams} from '@wix/panda-js-utils'
import {CommonState, Multilingual} from '../types/state'

export const getEventUrl = (pageUrl: string = '', event: wix.events.Event, multilingual: Multilingual) => {
  const url = `${pageUrl}/${event.slug}`

  if (multilingual.isEnabled) {
    const lang = multilingual.currentLanguage
    return addQueryParams(url, {lang})
  }

  return url
}

export const getEventDateInformation = (state: CommonState, id: string) => state.dates.events[id]
